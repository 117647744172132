<template>
  <div @click="videoPlay">
    <div class="zaijiabangong">
      <div class="banner">
        <img
          src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/zjbg1.png"
          alt=""
        />
        <a
          href="https://www.wjx.cn/jq/39990671.aspx"
          target="_blank"
          class="baomingcanjia"
          >报名参加</a
        >
      </div>

      <div class="zjbg-w1200">
        <h1><span>北京海腾时代科技有限公司</span>在家办公第一季<i>2019</i></h1>
        <p class="zhu-jiejian"><span>在家办公给海腾带来的变化：</span></p>
        <p class="zhu-jiejian">
          <span>【节约公司成本】：</span
          >之前公司坐落在TBD，单独房租每个季度就得<i>16万</i>，现在房租不到<i>2万</i>。还省去了公司很多其他的开销；
        </p>
        <p class="zhu-jiejian">
          <span>【提高工作效率】：</span
          >公司员工在家办公通过<i>Xcanvas</i>，大大提高了工作效率，所有工作内容可视化，项目需求清晰明确；
        </p>
        <p class="zhu-jiejian">
          <span>【备受员工喜爱】：</span
          >在家办公不仅给员工节省了来回路途时间，而且工作更加<i>自由化</i>，彻底摆脱了传统的坐班制。
        </p>

        <div class="zhaopian-box">
          <div class="shijian"><span>8月</span></div>
          <div class="zhaopian-ulli-box cb">
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190801.png"
                  class="shouping"
                  alt=""
                /><i
                  class="index-pay2"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190801.png"
                  mp-src="/vedio/beijing8yue.mp4"
                ></i>
              </div>
              <h2>
                海腾时代8月份全球会议<img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/zjbg9.png"
                  alt=""
                />
              </h2>
              <p>上传时间：2019/09/10</p>
            </div>

            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190802.png"
                  class="shouping"
                  data-imgtime="20190802"
                />
              </div>
              <h2>
                北京同城聚会
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/zjbg9.png"
                  alt=""
                />
              </h2>
              <p>上传时间：2019/09/10</p>
            </div>

            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190803.png"
                  class="shouping"
                  alt=""
                /><i
                  class="index-pay2"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190803.png"
                  mp-src="/vedio/beijing8yuejh.mp4"
                ></i>
              </div>
              <h2>北京同城聚会video</h2>
              <p>上传时间：2019/09/10</p>
            </div>
          </div>
        </div>

        <div class="zhaopian-box">
          <div class="shijian"><span>7月</span></div>
          <div class="zhaopian-ulli-box cb">
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190701.png"
                  class="shouping"
                  alt=""
                /><i
                  class="index-pay2"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190701.png"
                  mp-src="/vedio/beijing7yue.mp4"
                ></i>
              </div>
              <h2>海腾时代7月份全球会议<img src="" alt="" /></h2>
              <p>上传时间：2019/08/10</p>
            </div>

            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190702.png"
                  class="shouping"
                  data-imgtime="20190702"
                />
              </div>
              <h2>北京同城聚会 <img src="" alt="" /></h2>
              <p>上传时间：2019/08/10</p>
            </div>

            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190703.png"
                  class="shouping"
                  alt=""
                /><i
                  class="index-pay2"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190703.png"
                  mp-src="/vedio/beijing7yuejh.mp4"
                ></i>
              </div>
              <h2>北京同城聚会video</h2>
              <p>上传时间：2019/08/10</p>
            </div>
          </div>
        </div>

        <div class="zhaopian-box">
          <div class="shijian"><span>6月</span></div>
          <div class="zhaopian-ulli-box cb">
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190601.png"
                  class="shouping"
                  alt=""
                /><i
                  class="index-pay2"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190601.png"
                  mp-src="/vedio/beijing6yue.mp4"
                ></i>
              </div>
              <h2>海腾时代6月份全球会议<img src="" alt="" /></h2>
              <p>上传时间：2019/07/10</p>
            </div>

            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190602.png"
                  class="shouping"
                  data-imgtime="20190602"
                />
              </div>
              <h2>北京同城聚会 <img src="" alt="" /></h2>
              <p>上传时间：2019/07/10</p>
            </div>

            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190603.png"
                  class="shouping"
                  alt=""
                /><i
                  class="index-pay2"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190603.png"
                  mp-src="/vedio/beijing6yuejh.mp4"
                ></i>
              </div>
              <h2>北京同城聚会video</h2>
              <p>上传时间：2019/07/10</p>
            </div>
          </div>
        </div>

        <div class="zhaopian-box">
          <div class="shijian"><span>5月</span></div>
          <div class="zhaopian-ulli-box cb">
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190501.png"
                  class="shouping"
                  alt=""
                /><i
                  class="index-pay2"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190501.png"
                  mp-src="/vedio/beijing5yue.mp4"
                ></i>
              </div>
              <h2>海腾时代5月份全球会议<img src="" alt="" /></h2>
              <p>上传时间：2019/06/04</p>
            </div>

            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190502.png"
                  class="shouping"
                  data-imgtime="20190502"
                />
              </div>
              <h2>北京同城聚会 <img src="" alt="" /></h2>
              <p>上传时间：2019/06/04</p>
            </div>

            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190503.png"
                  class="shouping"
                  alt=""
                /><i
                  class="index-pay2"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20190503.png"
                  mp-src="/vedio/quanqiuhuiyi.mp4"
                ></i>
              </div>
              <h2>北京同城聚会video</h2>
              <p>上传时间：2019/06/04</p>
            </div>
          </div>
        </div>

        <div class="zhaopian-box">
          <div class="shijian"><span>4月</span></div>
          <div class="zhaopian-ulli-box cb">
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/workfromhome_2.png"
                  class="shouping"
                  data-imgtime="workfromhome_2"
                />
              </div>
              <h2>北京团队同城聚会</h2>
              <p>上传时间：2019/05/05</p>
            </div>

            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/workfromhome_1.png"
                  class="shouping"
                  workfromhome_1
                  data-imgtime="workfromhome_1"
                />
              </div>
              <h2>武汉团队同城聚会</h2>
              <p>上传时间：2019/05/05</p>
            </div>

            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/fttx.png"
                  class="shouping"
                  alt=""
                /><i
                  class="index-pay2"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/fttx.png"
                  mp-src="/vedio/xiangmufuzerenhuiyi.mp4"
                ></i>
              </div>
              <h2>2019项目负责人特训</h2>
              <p>上传时间：2019/05/05</p>
            </div>
          </div>
        </div>

        <div class="zhaopian-box">
          <div class="shijian"><span>3月</span></div>
          <div class="zhaopian-ulli-box cb">
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/workfromhome_3.png"
                  class="shouping"
                  data-imgtime="workfromhome_3"
                />
              </div>
              <h2>武汉团队同城聚会 <img src="" alt="" /></h2>
              <p>上传时间：2019/04/05</p>
            </div>
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/workfromhome_4.png"
                  class="shouping"
                  data-imgtime="workfromhome_4"
                />
              </div>
              <h2>北京团队同城聚会 <img src="" alt="" /></h2>
              <p>上传时间：2019/04/05</p>
            </div>
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/ftys.png"
                  class="shouping"
                  alt=""
                /><i
                  class="index-pay2"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/ftys.png"
                  mp-src="/vedio/lvyongshengfangtan.mp4"
                ></i>
              </div>
              <h2>在家办公专访：安卓开发工程师-吕永胜</h2>
              <p>上传时间：2019/04/05</p>
            </div>
          </div>
        </div>

        <div class="zhaopian-box">
          <div class="shijian"><span>2月</span></div>
          <div class="zhaopian-ulli-box cb">
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/workfromhome_5.png"
                  class="shouping"
                  data-imgtime="workfromhome_5"
                />
              </div>
              <h2>北京同城员工生日party <img src="" alt="" /></h2>
              <p>上传时间：2019/03/05</p>
            </div>
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/ftxc.png"
                  class="shouping"
                  alt=""
                /><i
                  class="index-pay2"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/ftxc.png"
                  mp-src="/vedio/liuxinchenfangtan.mp4"
                ></i>
              </div>
              <h2>在家办公专访：新媒体运营-刘芯辰</h2>
              <p>上传时间：2019/03/05</p>
            </div>
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/ftzk.png"
                  class="shouping"
                  alt=""
                /><i
                  class="index-pay2"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/ftzk.png"
                  mp-src="/vedio/zhangkaifangtan.mp4"
                ></i>
              </div>
              <h2>在家办公专访：iOS开发工程师-张凯</h2>
              <p>上传时间：2019/03/05</p>
            </div>
          </div>
        </div>

        <div class="zhaopian-box">
          <div class="shijian"><span>1月</span></div>
          <div class="zhaopian-ulli-box cb">
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/workfromhome_6.png"
                  class="shouping"
                  data-imgtime="workfromhome_6"
                />
              </div>
              <h2>武汉同城员工庆生 <img src="" alt="" /></h2>
              <p>上传时间：2019/02/05</p>
            </div>
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/workfromhome_7.png"
                  class="shouping"
                  data-imgtime="workfromhome_7"
                />
              </div>
              <h2>武汉员工聚会活动 <img src="" alt="" /></h2>
              <p>上传时间：2019/02/05</p>
            </div>
            <div>
              <div class="img-box">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/ftwl.png"
                  class="shouping"
                  alt=""
                /><i
                  class="index-pay2"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/ftwl.png"
                  mp-src="/vedio/weilanfangtan.mp4"
                ></i>
              </div>
              <h2>在家办公专访：新媒体运营-魏岚</h2>
              <p>上传时间：2019/02/05</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="piaofu2" v-if="piaofu2">
      <div class="shipin2">
        <div class="alertxxx" @click="piaofu2 = false"></div>
        <video
          id="shipin"
          ref="video"
          :src="vsrc"
          :poster="poster"
          controls
        ></video>
      </div>
    </div>
    <div class="piaofu2" v-if="piaofu1" @click="closeMask">
      <div class="tupian_center_box">
        <div class="zhu-img" id="focus">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <img
                class="swiper-slide"
                v-for="item in imgList"
                :key="item"
                :src="item"
              />
            </div>
            <div class="swiper-pagination"></div>
            <!--分页器。如果放置在swiper-container外面，需要自定义样式。-->
          </div>
        </div>
        <div class="xiao-img" @click="navSlide">
          <span
            :class="{ on: acImg === idx }"
            v-for="(item, idx) in imgList"
            :key="item"
            :data-idx="idx"
            ><img :src="item"
          /></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";

export default {
  name: "WorkFromHome",
  watch: {
    imgTime(val) {
      let list = [];
      for (let i = 1; i < 5; i++) {
        list.push(
          `https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/${val}0${i}.png`
        );
      }
      this.acImg = 0;
      this.imgList = list;
      this.$nextTick(this.initSwiper);
    }
  },
  data() {
    return {
      acImg: 0,
      swiper: null,
      piaofu1: false,
      piaofu2: false,
      vsrc: null,
      poster: null,
      imgTime: null,
      imgList: []
    };
  },
  methods: {
    videoPlay({ target }) {
      let vsrc = target.getAttribute("mp-src");
      if (!vsrc) return this.imgPlay(arguments[0]);
      this.piaofu2 = true;
      this.vsrc = vsrc;
      this.poster = target.getAttribute("poster-src");
      this.$nextTick(() => {
        this.$refs.video.play();
      });
    },
    imgPlay({ target }) {
      let time = target.dataset.imgtime;
      if (!time) return;
      this.piaofu1 = true;
      this.imgTime = time;
      console.log(this.imgTime);
    },
    initSwiper() {
      let _this = this;
      this.swiper = new Swiper(".swiper-container", {
        autoplay: {
          delay: 3000 //1秒切换一次
        },
        on: {
          slideChange() {
            _this.acImg = this.activeIndex;
          }
        }
      });
    },
    navSlide({ target }) {
      let idx = target.dataset.idx;
      if (!idx) return;
      this.swiper && this.swiper.slideTo(idx - 0);
    },
    closeMask({ target }) {
      if (target.className === "piaofu2") {
        this.piaofu1 = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~swiper/swiper-bundle.min";
</style>
