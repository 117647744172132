<template>
  <div>
    <div class="index-banner">
      <h2>Xcanvas 可视化创意协同工作平台</h2>
      <h3>创新创造 随时随地</h3>
      <a
        href="https://dev.x-canvas.net/dis/auth/login"
        class="ljitiyan pc-ljitiyan"
        >立即体验</a
      >
      <a
        href="https://dev.x-canvas.net/dis/auth/login"
        class="ljitiyan wap-ljitiyan"
        >立即体验</a
      >
      <div class="index-pay-box">
        <img
          @click="indexPay"
          src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/14.png"
          class="index-pay shouping"
        />
        <div class="piaofu" v-if="shipin">
          <div class="alertxxx" @click="shipin = false"></div>
          <video
            ref="video"
            id="shipin"
            :src="mp4src"
            :poster="poster"
            controls
          ></video>
        </div>
      </div>
    </div>
    <div class="bgfff">
      <h3 class="sheishuo">谁说的？一起工作，非要一起办公呢！</h3>
      <div class="index-ditu">
        <img
          src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/index20.png"
          alt=""
        />
      </div>

      <div class="index-box">
        <div class="cb">
          <div class="wenzi-box fr">
            <h3>哪里不懂点哪里</h3>
            <p>跟疑问say goodbay</p>
          </div>
          <div class="tupian-box fl">
            <img
              src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/index18.png"
              alt=""
            />
          </div>
        </div>

        <div class="cb mt80">
          <div class="wenzi-box zuobian fl">
            <h3>所有文件云储存</h3>
            <p>任何资料你都可以放在paper里</p>
          </div>
          <div class="tupian-box fr">
            <img
              src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/index2.png"
              alt=""
            />
          </div>
        </div>

        <div class="cb mt80">
          <div class="wenzi-box fr">
            <h3>项目全程可视化协作</h3>
            <p>项目需求明明白白，团队成员高效协同</p>
          </div>
          <div class="tupian-box fl">
            <img
              src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/index19.png"
              alt=""
            />
          </div>
        </div>

        <div class="cb mt80">
          <div class="wenzi-box zuobian fl" style="width: 660px">
            <h3>多平台协同</h3>
            <p>支持游览器，微信，苹果，安卓，iPad等多平台</p>
          </div>
          <div class="tupian-box fr">
            <img
              src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/index21.png"
              alt=""
              style="width: 500px"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="index-lunbo-box">
      <div class="w1200 por">
        <div class="main_image2">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div>
                  <div class="main_image_img">
                    <a href="https://jisuoffice.com/" target="_blank"
                      ><img
                        src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/new_27.png"
                    /></a>
                  </div>
                  <a href="https://jisuoffice.com/" target="_blank">
                    <div class="main_image_wenzi">极速Office</div>
                    <p class="newjianjie">
                      极速Office是一款轻快、无广告的office办公软件，支持极速写作、极速表格、极速演示的创建、编辑、阅读；全面兼容微软Office97-2019格式；界面简洁、操作简单，随心随地使用，是您身边高效省心的办公助手！
                    </p>
                  </a>
                </div>
                <div>
                  <div class="main_image_img">
                    <a href="https://jisupdf.com/" target="_blank"
                      ><img
                        src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/new_10.png"
                    /></a>
                  </div>
                  <a href="https://jisupdf.com/" target="_blank">
                    <div class="main_image_wenzi">极速PDF</div>
                    <p class="newjianjie">
                      一款闪电般好用的PDF阅读器，体积小、启动速度快，且占用内存极少；提供单页、书本模式个性阅读，又有全屏、幻灯片等功能助您随心切换；全新定义注释：写字、高亮、画线等功能让您的阅读、随心笔记更轻松，更省事！
                    </p>
                  </a>
                </div>
                <div>
                  <div class="main_image_img">
                    <a href="https://pc.jisutodo.com/" target="_blank"
                      ><img
                        src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/new_15.png"
                    /></a>
                  </div>
                  <a href="https://pc.jisutodo.com/" target="_blank">
                    <div class="main_image_wenzi">极速玩转</div>
                    <p class="newjianjie">
                      极速玩转是一个简单，快速，完美的文档转换平台。功能齐全：支持15种：如PDF、Word、Excel、PPT、JPG等格式互转。完美转换：支持一键批量上传转换，转换准确率高，排版好。并提供优质VIP服务。
                    </p>
                  </a>
                </div>
                <div>
                  <div class="main_image_img">
                    <a href="http://www.jisupdfeditor.com/" target="_blank"
                      ><img
                        src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/new_29.png"
                    /></a>
                  </div>
                  <a href="http://www.jisupdfeditor.com/" target="_blank">
                    <div class="main_image_wenzi">极速PDF编辑器</div>
                    <p class="newjianjie">
                      一款简单好用的PDF编辑器，支持一键段落编辑，告别繁琐费时的行间编辑；自动识别字体，省时省力；可进行全文标注，让您阅读文献、资料更加方便；具有自动生成书签、增删页面，插入、签章、高亮等功能，是您PDF编辑不错的选择！
                    </p>
                  </a>
                </div>
              </div>
              <div class="swiper-slide">
                <div>
                  <div class="main_image_img">
                    <a href="http://pc.jisupdftoword.com/" target="_blank"
                      ><img
                        src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/new_35.png"
                    /></a>
                  </div>
                  <a href="http://pc.jisupdftoword.com/" target="_blank">
                    <div class="main_image_wenzi">极速PDF转Word</div>
                    <p class="newjianjie">
                      一款好用又便捷的PDF转换器，支持PDF转word、PDF合并拆分、PDF加解密，及批量在线转换：Word转PDF、JPG转PDF等功能；界面简洁大方，操作简单；省时省力，大大提高办公效率！
                    </p>
                  </a>
                </div>
                <div>
                  <div class="main_image_img">
                    <a href="https://jisuimg.com/" target="_blank"
                      ><img
                        src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/new_12.png"
                    /></a>
                  </div>
                  <a href="https://jisuimg.com/" target="_blank">
                    <div class="main_image_wenzi">极速img</div>
                    <p class="newjianjie">
                      极速img是全球智能图片领跑平台，为个人、企业、政务提供图片“无损压缩、检索、标注、读取、存储、分享”等智慧服务；极速img-智慧图片服务，您身边的好助手！
                    </p>
                  </a>
                </div>
                <div>
                  <div class="main_image_img">
                    <a href="https://huahuacool.com/" target="_blank"
                      ><img
                        src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/new_32.png"
                    /></a>
                  </div>
                  <a href="https://huahuacool.com/" target="_blank">
                    <div class="main_image_wenzi">花花世界</div>
                    <p class="newjianjie">
                      一个创造，发现，分享世界最美图片的平台，你可以随时随地拥有，分享你最美、最喜欢的照片及素材；全面兼容JPG、BMP、GIF、PSD、WEBP、RAM等格式，且一键截图、随意分享，是您最佳的美图助手！
                    </p>
                  </a>
                </div>
                <div>
                  <div class="main_image_img">
                    <a href="https://xcanvas.cn/" target="_blank"
                      ><img
                        src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/new_08.png"
                    /></a>
                  </div>
                  <a href="https://xcanvas.cn/" target="_blank">
                    <div class="main_image_wenzi">Xcanvas</div>
                    <p class="newjianjie">
                      Xcanvas是新一代可视化办公协作平台，是OA、Email、白板、电话、投影等的终结者远程协作，数据实时存储，是日常工作、项目交流、头脑风暴、团队沟通、会议记录、视频会议、互动教学、私人笔记等等的好帮手。
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="btn_prev2" @click="prev"></div>
        <div id="btn_next2" @click="next"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";

export default {
  name: "Product",
  data() {
    return {
      shipin: false,
      mp4src: "/vedio/xpaper1920p.mp4",
      poster:
        "https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/14.png",
      image2W: 1200
    };
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    indexPay({ target }) {
      this.shipin = true;
      this.$nextTick(() => {
        this.$refs.video.play();
      });
    },
    initSwiper() {
      setTimeout(() => {
        this.swiper = new Swiper(".swiper-container", {
          autoplay: {
            delay: 6000 //1秒切换一次
          },
          loop: true
        });
      }, 200);
    },
    prev() {
      this.swiper && this.swiper.slidePrev();
    },
    next() {
      this.swiper && this.swiper.slideNext();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~swiper/swiper-bundle.min";
</style>
