<template>
  <div>
    <div class="center_main">
      <div class="list-nav-tab cb">
        <span :class="{ hot: acIdx === 0 }" @click="acIdx = 0">日常办公</span>
        <span :class="{ hot: acIdx === 1 }" @click="acIdx = 1">学习场景</span>
        <span :class="{ hot: acIdx === 2 }" @click="acIdx = 2">生活场景</span>
      </div>

      <div class="list-center-tab" @click="videoPlay">
        <div class="center-box" v-show="acIdx === 0">
          <!-- 一栏 -->
          <div class="img-box-1 por mt40">
            <img
              src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/1.png"
              alt=""
              class="index-pay shouping"
              poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/1.png"
              mp-src="/vedio/rd0522.mp4?bangong"
            />
            <div class="h3-box">
              <h3>产品研发</h3>
              <span>5608</span>
            </div>
          </div>
          <!-- /一栏 -->
          <!-- 二栏 -->
          <div class="mt40 cb">
            <div class="img-box-2 por">
              <img
                src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/24.png"
                alt=""
                class="index-pay2 shouping"
                poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/yy4.png"
                mp-src="/videos/huiyijilu.mp4?bangong"
              />
              <div class="h3-box">
                <h3>会议记录</h3>
                <span>3467</span>
              </div>
            </div>
            <div class="img-box-2-2 ">
              <div class=" por">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/3.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/3.png"
                  mp-src="/videos/peixunjihua.mp4?bangong"
                />
                <div class="h3-box">
                  <h3>培训计划</h3>
                  <span>3467</span>
                </div>
              </div>
              <div class="por mt22">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/6.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/6.png"
                  mp-src="/videos/xiangmuguanli.mp4?bangong"
                />
                <div class="h3-box">
                  <h3>项目管理</h3>
                  <span>3901</span>
                </div>
              </div>
            </div>
          </div>
          <!-- /二栏 -->
          <!-- 三栏 -->
          <div class="mt40">
            <div class="img-box-3 cb">
              <div class="por">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/8.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/8.png"
                  mp-src="#"
                />
                <div class="h3-box">
                  <h3>人事招聘</h3>
                  <span>3806</span>
                </div>
              </div>
              <div class="por">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/10.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/10.png"
                  mp-src="/videos/meirifansi.mp4?bangong"
                />
                <div class="h3-box">
                  <h3>每周反思</h3>
                  <span>2390</span>
                </div>
              </div>
              <div class="por">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/12.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/12.png"
                  mp-src="/videos/huodongcehua.mp4?bangong"
                />
                <div class="h3-box">
                  <h3>活动策划</h3>
                  <span>3905</span>
                </div>
              </div>
            </div>
          </div>
          <!-- /三栏 -->
        </div>
        <div class="center-box" v-show="acIdx === 1">
          <!-- 一栏 -->
          <div class="img-box-1 por mt40">
            <img
              src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/2.png"
              alt=""
              class="index-pay shouping"
              poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/2.png"
              mp-src="/videos/dushujihua.mp4?xuexi"
            />
            <div class="h3-box">
              <h3>读书计划</h3>
              <span>5890</span>
            </div>
          </div>
          <!-- /一栏 -->
          <!-- 二栏 -->
          <div class="mt40 cb">
            <div class="img-box-2 por">
              <img
                src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/3.png"
                alt=""
                class="index-pay2 shouping"
                poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/3.png"
                mp-src="/videos/xuexijihua.mp4?xuexi"
              />
              <div class="h3-box">
                <h3>学习计划</h3>
                <span>4951</span>
              </div>
            </div>
            <div class="img-box-2-2 ">
              <div class=" por">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/5.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/5.png"
                  mp-src="/videos/kechengbiao.mp4?xuexi"
                />
                <div class="h3-box">
                  <h3>课程表</h3>
                  <span>3902</span>
                </div>
              </div>
              <div class="por mt22">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/7.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/7.png"
                  mp-src="/videos/jiaoxuekejian.mp4?xuexi"
                />
                <div class="h3-box">
                  <h3>教学课件</h3>
                  <span>2943</span>
                </div>
              </div>
            </div>
          </div>
          <!-- /二栏 -->
          <!-- 三栏 -->
          <div class="mt40">
            <div class="img-box-3 cb">
              <div class="por">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/13.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/13.png"
                  mp-src="#"
                />
                <div class="h3-box">
                  <h3>书籍收藏</h3>
                  <span>2378</span>
                </div>
              </div>
              <div class="por">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/11.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/11.png"
                  mp-src="/videos/kangnaierbiji.mp4?xuexi"
                />
                <div class="h3-box">
                  <h3>康奈尔笔记</h3>
                  <span>4509</span>
                </div>
              </div>
              <div class="por">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/9.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/9.png"
                  mp-src="/videos/chaishubiji.mp4?xuexi"
                />
                <div class="h3-box">
                  <h3>拆书笔记</h3>
                  <span>3302</span>
                </div>
              </div>
            </div>
          </div>
          <!-- /三栏 -->
        </div>
        <div class="center-box" v-show="acIdx === 2">
          <!-- 一栏 -->
          <div class="img-box-1 por mt40">
            <img
              src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/15.png"
              alt=""
              class="index-pay shouping"
              poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/17.png"
              mp-src="/videos/shanshijihua.mp4?shenghuo"
            />
            <div class="h3-box">
              <h3>膳食计划</h3>
              <span>3901</span>
            </div>
          </div>
          <!-- /一栏 -->
          <!-- 二栏 -->
          <div class="mt40 cb">
            <div class="img-box-2 por">
              <img
                src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/16.png"
                alt=""
                class="index-pay2 shouping"
                poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/16.png"
                mp-src="/videos/biyeji.mp4?shenghuo"
              />
              <div class="h3-box">
                <h3>毕业相册</h3>
                <span>4019</span>
              </div>
            </div>
            <div class="img-box-2-2 ">
              <div class=" por">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/17.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/17.png"
                  mp-src="#"
                />
                <div class="h3-box">
                  <h3>每天一道美食</h3>
                  <span>3068</span>
                </div>
              </div>
              <div class="por mt22">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/18.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/18.png"
                  mp-src="/videos/chenjianriji-girl.mp4?shenghuo"
                />
                <div class="h3-box">
                  <h3>晨间日记</h3>
                  <span>3988</span>
                </div>
              </div>
            </div>
          </div>
          <!-- /二栏 -->
          <!-- 三栏 -->
          <div class="mt40">
            <div class="img-box-3 cb">
              <div class="por">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/21.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/21.png"
                  mp-src="/videos/xingchengjihua.mp4?shenghuo"
                />
                <div class="h3-box">
                  <h3>行程计划</h3>
                  <span>2907</span>
                </div>
              </div>
              <div class="por">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/20.png"
                  mp-src="/videos/shouzhang.mp4?shenghuo"
                />
                <div class="h3-box">
                  <h3>旅行手账</h3>
                  <span>3016</span>
                </div>
              </div>
              <div class="por">
                <img
                  src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/19.png"
                  alt=""
                  class="index-pay2 shouping"
                  poster-src="https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/19.png"
                  mp-src="/videos/chaishubiji.mp4?shenghuo"
                />
                <div class="h3-box">
                  <h3>拆书笔记</h3>
                  <span>2805</span>
                </div>
              </div>
            </div>
          </div>
          <!-- /三栏 -->
        </div>
      </div>
    </div>
    <div class="piaofu2" v-if="piaofu2">
      <div class="shipin2">
        <div class="alertxxx" @click="piaofu2 = false"></div>
        <video
          id="shipin"
          ref="video"
          :src="vsrc"
          :poster="poster"
          controls
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Scenarios",
  data() {
    return {
      acIdx: 0,
      piaofu2: false,
      vsrc: null,
      poster: null
    };
  },
  methods: {
    videoPlay({ target }) {
      let vsrc = target.getAttribute("mp-src");
      if (!vsrc) return;

      this.piaofu2 = true;
      this.vsrc = vsrc;
      this.poster = target.getAttribute("poster-src");
      this.$nextTick(() => {
        this.$refs.video.play();
      });
    }
  }
};
</script>

<style></style>
